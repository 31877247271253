/*
 * IMPORT
 */
import Link from "next/link";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import React, { useEffect, useState } from "react";

/*
 * STYLES
 */
import "swiper/css";
import "swiper/css/navigation";

export const Banner = () => {
  const [showPopup, setShowPopup] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowPopup(false);
    }, 10000);

    return () => clearTimeout(timer);
  }, []);

  const closePopup = () => {
    setShowPopup(false);
  };

  return (
    <>
      {/* <!-- BEGIN MAIN BLOCK --> */}
      {/* <Swiper
        spaceBetween={0}
        slidesPerView={1}
        // autoplay={{
        //     delay: 3000, // 5 seconds in milliseconds
        //     disableOnInteraction: false, // enable autoplay even when user interacts with slider
        // }}
        modules={[Autoplay]}
      > */}
      {/* {showPopup && (
            <div className={"modal"}>
              <div className={"modalContent"}>
                <span className={"close"} onClick={closePopup}>
                  &times;
                </span>
                <h5>Plan Your Subscription Now</h5>
                <img
                  src="https://img.freepik.com/free-photo/kids-home-playing-with-toys_23-2148630637.jpg?t=st=1713180540~exp=1713184140~hmac=78533d051e9a966725901d9fb293d8310195bc6cb832e79bd6c621a547fc0f56&w=2000"
                  alt="kids"
                  style={{ height: "250px", margin: "10px" }}
                />
                <h5>
                  Save a maximum of{" "}
                  <span
                    style={{
                      fontWeight: "bold",
                      fontSize: "28px",
                      textDecoration: "underline",
                      color: "#ffa41c",
                    }}
                  >
                    36%
                  </span>{" "}
                  in subscription
                </h5>
                <Link href="/how_it_work">
                  <button
                    style={{
                      height: "50px",
                      padding: "10px",
                      margin: "10px",
                      border: "none",
                      background: "black",
                      color: "#fff",
                    }}
                  >
                    Get Subscription
                  </button>
                </Link>
              </div>
            </div>
          )} */}

      <div className="main-block main-block-main load-bg">
        <div className="wrapper">
          <div className="main-block__content">
            <h1 className="main-text">
              <span className="second">Rent Toys </span>
            </h1>
            <p
              style={{
                backgroundImage:
                  "linear-gradient(to right, #ee1b24 0%, #3b57a6 100%)",
                backgroundClip: "text",
                webkitBackgroundClip: "text",
                webkitTextFillColor: "transparent",
              }}
            >
              Perfect for your child's age and interests at affordable prices
            </p>

            <Link href="/shop">
              <a className="btn btn-banner">Rent now</a>
            </Link>
          </div>
        </div>
      </div>

      {/* <SwiperSlide>
          <div className="main-block main-block-1 load-bg">
            <div className="wrapper">
              <div className="main-block__content">
                <h1 className="main-text">
                  Sustainable & <span className="second"> Affordable</span>
                </h1>
                <p>
                  Renting toys is not only eco-friendly, but it’s also budget
                  -friendly. Join us in reducing toy waste and saving money.
                </p>

                <Link href="/shop">
                  <a className="btn btn-banner-1">Rent now</a>
                </Link>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="main-block main-block-2 load-bg">
            <div className="wrapper">
              <div className="main-block__content">
                <h1 className="main-text">
                  Hassle Free <span className="second">Renting</span>
                </h1>
                <p style={{ fontFamily: "inherit" }}>
                  A Toy Library that rents big and expensive toys for as little
                  as one week
                </p>

                <Link href="/shop">
                  <a className="btn btn-banner-2">Rent now</a>
                </Link>
              </div>
            </div>
          </div>
        </SwiperSlide> */}
      {/* </Swiper> */}
      {/* <!-- MAIN BLOCK EOF --> */}
    </>
  );
};
