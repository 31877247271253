import { IoWalletOutline } from "react-icons/io5";
import { TbTruckDelivery } from "react-icons/tb";
import { TiSortAlphabeticallyOutline } from "react-icons/ti";
import { HiOutlineCheckBadge } from "react-icons/hi2";
import { whyUs } from "../../constants/whyUs";
import React from "react";
import { MdOutlineToys } from "react-icons/md";
import { BsCalendar2Date } from "react-icons/bs";
import { RiSecurePaymentLine } from "react-icons/ri";
import { howWeWork } from "../../constants/howWeWork";

const HowWeWork = () => {
  return (
    <>
      <h4 className="gradient-title why-us-title">How We Work?</h4>
      <div className="why-us-wrapper">
        {howWeWork.map((item, i) => (
          <div className="why-us">
            <div>{item.icon}</div>
            <h6>{item.title}</h6>
            <p className="why-us-desc">{item.description}</p>
          </div>
        ))}
      </div>
    </>
  );
};

export default HowWeWork;
