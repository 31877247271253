import { MdOutlineToys } from "react-icons/md";
import { BsCalendar2Date } from "react-icons/bs";
import { RiSecurePaymentLine } from "react-icons/ri";
import { HiOutlineCheckBadge } from "react-icons/hi2";
import React from "react";
import { IoConstructOutline } from "react-icons/io5";
import { LiaTruckPickupSolid } from "react-icons/lia";

export const howWeWork = [
  {
    title: "Select Your Toys!",
    description: (
      <>
        Visit{" "}
        <a href="https://www.jusplaytoys.com/shop">www.jusplaytoys.com/shop</a>{" "}
        to choose your favorite toys, or WhatsApp at{" "}
        <a
          target="_blank"
          href="https://api.whatsapp.com/send/?phone=919315303419&text=Happy+to+know+that+you+want+to+rent+toys+from+Jusplaytoys+%F0%9F%A4%A9%0AWe+are+excited+to+answer+your+questions+and+make+sure+you+have+a+great+time+renting+toys+from+us&type=phone_number&app_absent=0"
        >
          9315303419
        </a>{" "}
        to explore all available options!
      </>
    ),
    icon: <MdOutlineToys size={80} />,
  },
  {
    title: "Choose Your Date and Location",
    description:
      "Select your preferred delivery date and location, and we'll ensure your toys arrive right on schedule!",
    icon: <BsCalendar2Date size={70} />,
  },
  {
    title: "Make Payment",
    description:
      "Pay online with RazorPay on our website or through other secure options for WhatsApp orders. It's quick and easy!",
    icon: <RiSecurePaymentLine size={80} />,
  },
  {
    title: "Free Assembly at Your Place",
    description:
      "Return The current product and get a new product by following same steps",
    icon: <IoConstructOutline size={80} />,
  },
  {
    title: "Pickup on Your Selected Date",
    description:
      "We will arrange for pickup on the date you choose to ensure your convenience!",
    icon: <LiaTruckPickupSolid size={80} />,
  },
  {
    title: "Extend Your Rental",
    description:
      "Contact us at least 2 days in advance to extend your rental, subject to availability.",
    icon: <HiOutlineCheckBadge size={80} />,
  },
];
