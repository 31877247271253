export const allCategories = [
  {
    categoryName: "0-3 Years",
    slug: "0-3-years",
  },
  {
    categoryName: "Tent",
    slug: "  ",
  },
  {
    categoryName: "6-9 Years",
    slug: "6-9-years",
  },
  {
    categoryName: "Learn with Fun",
    slug: "learn-with-fun",
  },
  {
    categoryName: "9+ ",
    slug: "9-plus",
  },
  {
    categoryName: "Cars and Rides",
    slug: "cars-and-rides",
  },
  {
    categoryName: "Ball Pools",
    slug: "ball-pools",
  },
  {
    categoryName: "Pretend Play",
    slug: "pretend-play",
  },
  {
    categoryName: "Slides and Swings",
    slug: "slides-and-swings",
  },
  {
    categoryName: "Active Play",
    slug: "active-play",
  },
  {
    categoryName: "See Saw and Rockers",
    slug: "see-saw-and-rockers",
  },
  {
    categoryName: "All Time Favourites",
    slug: "all-time-favourites",
  },
  {
    categoryName: "Trampolines ",
    slug: "trampolines-",
  },
  {
    categoryName: "Kitchen Sets",
    slug: "kitchen-sets1",
  },
  {
    categoryName: "Roller Coasters",
    slug: "roller-coasters",
  },
  {
    categoryName: "And Much More",
    slug: "and-much-more",
  },
  {
    categoryName: "3-6 Years",
    slug: "3-6-years",
  },
  {
    categoryName: "Roller Coaster",
    slug: "roller-coasters",
  },
];

export const ageCategories = [
  {
    categoryName: "0-3 Years",
    slug: "0-3-years",
  },
  {
    categoryName: "6-9 Years",
    slug: "6-9-years",
  },
  {
    categoryName: "9+ ",
    slug: "9-plus",
  },
  {
    categoryName: "3-6 Years",
    slug: "3-6-years",
  },
];
