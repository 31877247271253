export const testimonials = [
  {
    name: 'Mansi Jhamb',
    img: 'https://lh3.googleusercontent.com/a-/ALV-UjXvY5atl8cagBym5Ae2S5lEzdDaABdsgBnU78t4zVdpir2y6BVJ=w60-h60-p-rp-mo-br100',
    comment: `I ordered JusPlay’s  party set up for twins birthday. They were very professional, the set up reached before time, toys were clean and the surprise toys part was aptly added to the things we selected for the set up. The kids had a blast at the party & this corner was the most loved!
    Thank you & highly recommend their services.`,
  },
  {
    name: 'Bhavna Kargeti',
    img: 'https://lh3.googleusercontent.com/a-/ALV-UjV4-cEgojgyw_3nbf6MdUGMiKenbCpCD8U2NlpWlqvP-8bGc4vE=w60-h60-p-rp-mo-br100',
    comment: `Recently we booked a play area setup for my my daughter birthday party and I just the love the all services , toys neat and clean on time setup and the best part kids enjoyed a lot with cute little play are setup .
  Thank you Neha for making my daughter birthday extra special’s`,
  },
  {
    name: 'Tannishtha M',
    img: 'https://lh3.googleusercontent.com/a-/ALV-UjU2DvyiemVvN5RKLVdziKmIX4ZRKaEMz1fV-kI5SgFOz--6oZ9wOQ=w60-h60-p-rp-mo-br100',
    comment: `Kids enjoyed a lot and the delivery and pickup was hassle free. Overall I would recommend their services and they have a great variety of toys to choose from as per age.
`,
  },
  {
    name: 'Soma Saha',
    img: 'https://lh3.googleusercontent.com/a/ACg8ocJE6YOnb-mNtnsvICj55BwJcFZDr2ClWm92_hvj2Ja4n_qnhA=w60-h60-p-rp-mo-br100',
    comment: `I have been renting toys from jusplay ever since I came to know about this site.. so budget friendly + huge variety.. it's a sure shot win win in our home.. since vacation has started for my kid we are gonna rent a lot of toys.. thanks Jusplay team for always timely delivery and super customer service support..
`,
  },
  {
    name: 'Shobhit Khare',
    img: 'https://lh3.googleusercontent.com/a-/ALV-UjWdEPjniXUcIVmaxNSK4F6BKy4-luD9NrAXxFXzmyMTOH6cs3sebQ=w60-h60-p-rp-mo-ba2-br100',
    comment:
      'We had availed JusPlay services to setup a play area for our daughter’s birthday party and boy, were we delighted! Right from the get-go, we received helpful advice on the play area design and the right selection of toys tailored for our party. I was very impressed with the professionalism with which JusPlay handled everything - including impeccable logistics, high quality toys, punctuality, proactive updates and above all - a client delight mindset. ',
  },
]
